@font-face {
  font-family: "NunitoMedium";
  src: local("NunitoMedium"),
   url("./fonts/Nunito/Nunito-VariableFont_wght.ttf") format("truetype");
  font-weight: bold;
 }

* {
  font-family: "NunitoMedium", monospace;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: "NunitoMedium", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.fixedWIdth {
  width: 900px;
  padding-bottom: 10px;
}

.fixedWIdth .showPrint {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  text-align: center;
  font-weight: bolder;
}

.imageSquare {
  height: auto;
  width: 100%;
}

.logoSquare {
  height: auto;
  width: 100%;
}

.rc-time-picker {
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.rc-time-picker-panel {
  font-size: 20px !important;
  z-index: 99999 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.rc-time-picker-input {
  font-size: 16px !important;
  height: 56px !important;
  padding: 15px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  display: flex;
}

.rc-time-picker-panel-input-wrap {
  color: rgba(0, 0, 0, 0.87) !important;
}

.rc-time-picker-panel-input {
  font-size: 16px !important;
  padding-top: 11px !important;
  padding-left: 7px !important;
}

.rc-time-picker-clear {
  display: none;
}
.rc-time-picker-clear-icon {
  display: none;
}
.headerTimeline {
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 0;
  background-color: #233F91;
  color: #FFFFFF;
  .rct-dateHeader {
    background-color: #233F91;
    .rct-dateHeader-primary {
      background-color: #233F91;
    }
  }
}
.logo_sblImg {
  max-height: 100%;
}
.min_logo_sbl {
  background-color: #FAFAFA;
  width: 50px;
  justify-content: center;
  cursor: pointer;
}
.inputRoot {
  & .MuiInputBase-root.Mui-disabled {
    color: #233F91;
  }
}
.firstSection {
  grid-row: 1;
  grid-column: 1;
  gap: "10%";
  display: "grid";
  grid-template-columns: "auto auto";
  grid-template-rows: "auto auto";
}

.css-lxhgdp{
  display: grid !important;
  grid-template-columns: 120px !important;
  gap: 2px !important;
  position: absolute;
  background-color: #FFFFFF;
  margin-left: -10px;
}

.pin1 {
  position: absolute;
  top: 40%;
  left: 50%;
  
  border-radius: 50% 50% 50% 0;
  border: 4px solid #fff;
  width: 20px;
  height: 20px;
  
}

.marker-label {
  margin-bottom: 60px;
  max-width: 100px;
  min-width: 100px;
  width: 100px;
  white-space: "nowrap",
}

@layer base {
  :root {
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }
 
  .dark {
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}